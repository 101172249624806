import { reactive, toRefs, onBeforeMount, onMounted } from 'vue';
export default {
  name: '',
  props: [],

  setup() {
    const state = reactive({});
    const refState = toRefs(state);
    onBeforeMount(() => {});
    onMounted(() => {});
    return { ...refState
    };
  }

};